<template>
  <div id="agencies-list">
    <b-overlay
      :show="overlayList"
      opacity="1.0"
      spinner-small
      spinner-variant="primary"
      @hidden="_onHidden()"
    >
      <div class="d-flex align-items-end justify-content-end">
        <b-button variant="primary" @click.prevent="_createAgency()">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle"> Nueva Inmobiliaria </span>
        </b-button>
      </div>
      <b-row>
        <b-col cols="12">
          <b-card no-body class="mb-0">
            <div class="m-2">
              <b-row>
                <b-col xl="6" sm="12" class="pl-0">
                  <b-form-group label="Inmobiliarias" label-for="inmobiliarias">
                    <v-select
                      v-model="filtersForm.real_state_agency"
                      label="name"
                      placeholder="Selecciona una inmobiliaria"
                      :options="realStateAgencies"
                      :reduce="realStateAgencies => realStateAgencies.id"
                    >
                      <span slot="no-options">
                        No se encontraron opciones
                      </span>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <div class="scroller mt-2 mb-1">
        <b-table
          hover
          striped
          show-empty
          class="position-relative mb-1"
          :items="agencies.data"
          :fields="tableColumns"
          empty-text="Datos no Disponibles"
        >
          <template #cell(logo)="data">
            <div class="container-img">
              <b-img height="80" :src="_getLogo(data.item.medias)" />
            </div>
          </template>
          <template #cell(visible)="data">
            <b-form-checkbox
              switch
              inline
              name="check-status"
              v-model="data.item.visible"
              @change="_changeStatus(data.item)"
            />
          </template>
          <template #cell(important)="data">
            <b-form-checkbox
              class="custom-control-primary"
              switch
              inline
              name="important"
              v-model="data.item.important"
              value="1"
              unchecked-value="0"
              @change="_editRealstateAgencyImportant(data.item)"
            >
            </b-form-checkbox>
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  size="16"
                  icon="MoreVerticalIcon"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click.prevent="_auditAgency(data.item)">
                <feather-icon icon="BookmarkIcon" />
                <span class="align-middle ml-50">Auditoria</span>
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="_editAgency(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </div>
      <b-pagination
        align="right"
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="agencies.total"
      />
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'AgenciesList',
  data () {
    return {
      overlayList: false,
      currentPage: 1,
      tableColumns: [],
      filtersForm: {
        real_state_agency: '',
        page: 1
      }
    }
  },
  beforeMount () {
    var userrole = this.$PermissionHelper.getRole().toLowerCase()
    if (userrole == 'admin' || userrole == 'superadmin') {
      this.tableColumns = [
        { key: 'logo', sortable: false, label: 'Logo' },
        { key: 'name', sortable: false, label: 'Nombre de Fantasía' },
        { key: 'representative', sortable: false, label: 'Nombre de Contacto' },
        { key: 'email', sortable: false, label: 'Email de Contacto' },
        { key: 'phone', sortable: false, label: 'Telefóno de Contacto' },
        { key: 'orden', sortable: false, label: 'Orden' },
        { key: 'visible', sortable: false, label: 'Visible/Oculto' },
        { key: 'important', sortable: false, label: 'Destacado' },
        { key: 'actions', label: 'Acciones' }
      ]
    } else {
      this.tableColumns = [
        { key: 'logo', sortable: false, label: 'Logo' },
        { key: 'name', sortable: false, label: 'Nombre de Fantasía' },
        { key: 'representative', sortable: false, label: 'Nombre de Contacto' },
        { key: 'email', sortable: false, label: 'Email de Contacto' },
        { key: 'phone', sortable: false, label: 'Telefóno de Contacto' },
        { key: 'orden', sortable: false, label: 'Orden' },
        { key: 'visible', sortable: false, label: 'Visible/Oculto' },
        { key: 'actions', label: 'Acciones' }
      ]
    }
    this._fetchAgencies()
  },
  computed: {
    ...mapState('agencies', ['agencies']),
    ...mapGetters('profile', ['realStateAgencies'])
  },
  watch: {
    currentPage (val) {
      this.filtersForm.page = this.currentPage
      this._fetchAgencies()
    },
    'filtersForm.real_state_agency' () {
      this._fetchAgencies()
    }
  },
  methods: {
    ...mapActions('agencies', [
      'fetchAgencies',
      'changeStatus',
      'editRealstateAgencyImportant'
    ]),
    ...mapActions('profile', [
      'fetchRealStateAgenciesNoPagination',
      'fetchRealStateAgencies'
    ]),
    async _fetchAgencies () {
      this.overlayList = true
      let request_json = { page: this.filtersForm.page }
      if (this.filtersForm.real_state_agency) {
        request_json = {
          ...request_json,
          id: this.filtersForm.real_state_agency
        }
      }
      await this.fetchAgencies(request_json).finally(() => {
        this.overlayList = false
      })
    },
    async _changeStatus (agency) {
      let visibleForm = { id: agency.id, visible: agency.visible }
      await this.changeStatus(visibleForm)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Estado modificado con éxito',
              icon: 'ThumbsUpIcon',
              variant: 'success'
            }
          })
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al modificar el estado',
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          })
        })
    },
    async _editRealstateAgencyImportant (data) {
      let importantForm = { agency_id: data.id, important: data.important }
      await this.editRealstateAgencyImportant(importantForm)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Estado modificado con éxito',
              icon: 'ThumbsUpIcon',
              variant: 'success'
            }
          })
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al modificar el estado',
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          })
        })
    },
    _createAgency () {
      this.$router.push({ name: 'agencies-create' })
    },
    _auditAgency (agency) {
      this.$router.push({
        name: 'agencies-audits',
        params: { id: agency.id, audits: agency }
      })
    },
    _editAgency (agency) {
      this.$router.push({
        name: 'agencies-edit',
        params: { id: agency.id, agency: agency }
      })
    },
    _getLogo (agency) {
      var today = new Date()
      var time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
      let miniature = agency.filter(agency => {
        return agency.mediatype.id === 1
      })
      return miniature.length > 0
        ? miniature[0].full_url + '?' + time
        : 'https://safetyaustraliagroup.com.au/wp-content/uploads/2019/05/image-not-found.png'
    },
    _onHidden () {
      if (this.$refs.button != null) {
        this.$refs.button.focus()
      }
    }
  }
}
</script>

<style>
.container-img {
  width: 120px;
}
.scroller {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
}
</style>
