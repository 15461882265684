<template>
  <div id="agencies">
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12">
            <agencies-list></agencies-list>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
  import AgenciesList from './components/AgenciesList'

  export default{
    name: 'AgenciesView',
    components: {AgenciesList},
    data() {
      return {
      }
    },
  }
</script>